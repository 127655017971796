// Build-time application config switches
// Allows us to disable certain features for GovCloud
// or offline mode.

export const DISABLE_INTERCOM =
  process.env.REACT_APP_GOVCLOUD === 'true' ||
  process.env.REACT_APP_IS_OFFLINE === 'true';
export const DISABLE_GA4 =
  process.env.REACT_APP_GOVCLOUD === 'true' ||
  process.env.REACT_APP_IS_OFFLINE === 'true' ||
  // eslint-disable-next-line
  // @ts-ignore
  window.Cypress;
export const DISABLE_SENTRY =
  process.env.REACT_APP_DISABLE_SENTRY === 'true' ||
  process.env.REACT_APP_GOVCLOUD === 'true' ||
  process.env.REACT_APP_IS_OFFLINE === 'true';

export const DISABLE_ONBOARDING = false;

export const API_BASE_URL = process.env.REACT_APP_IS_ELECTRON
  ? `${process.env.REACT_APP_API_ENDPOINT}/api/v0`
  : '/api/v0';

export const SENTRY_HACK_FOR_MATPLOTLIB = true;

// Renderer config
// This flag enables super smooth moving of blocks, links and annotations
// by skipping the updates to redux until mouseUp. There is likely little
// difference on powerful machines and with the prod build, but this is
// very noticeable on slower machines and/or with the dev build.
// Known bugs:
// - updates are not sent to other multiplayer clients.
// - comment bubbles don't follow moving blocks. (panning is ok)
export const RENDERER_EXPERIMENTAL_SMOOTH_ENTITY_MOVE = true;

// A new canvas 2d renderer that fully matches the NanoVG/WebGL renderer,
// but is significantly faster.
export const RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D = true;

// Set this to true if you are running benchmarks or need faster hot reload.
// This must be false in production!
export const DISABLE_PYODIDE = false && process.env.NODE_ENV !== 'production';
